export default function HighLight (val, text, search) {
  val = text
  if (!val) {
    return ''
  }
  if (search && search.length > 0) {
    // 匹配关键字正则
    const replaceReg = new RegExp(search, 'g')
    // 高亮替换v-html值
    const replaceString = '<span class="search-text" style= "color:#6F8CF3">' + search + '</span>'
    // 开始替换
    val = val.replace(replaceReg, replaceString)
  }
  return val
}
