import axios from './request'

// 科室分类接口
export const getDepartment = (rows, page, dempFirst, dempSecond, isIndex) => axios.get('/department/queryDepartment', {
  params: {
    rows,
    page,
    dempFirst,
    dempSecond,
    isIndex
  }
})

// 科室分类接口
export const getMediaInfoList = (filterCondition, rows, page, type) => axios.get('/common/mediaInfoList', {
  params: {
    filterCondition,
    rows,
    page,
    type
  }
})

// 医生card信息接口
export const getDoctorInfoList = (filterCondition, rows, page, type) => axios.get('/doctor/doctorInfoList', {
  params: {
    filterCondition,
    rows,
    page,
    type
  }
})

// 提交反馈接口
export const addAdvance = (userName, phoneOrEmail, advance) => axios.post('/common/addAdvance', {
  params: {
    userName,
    phoneOrEmail,
    advance
  }
})

// 点赞接口
export const addzan = (infoId, infoType, thumsup) => axios.post('/common/addThumbsup', {
  infoId,
  infoType,
  thumsup
})

// 获取轮播图
export const getBanner = (type) => axios.get('/common/queryBannerList', {
  params: {
    type
  }
})

// 获取轮播图
export const getTitle = (type) => axios.get('common/todayRecommendList')

// 提交反馈接口
export const getdetailList = (rows, page, filterCondition, type, infoTag) => axios.get('/common/mediaInfoList', {
  params: {
    rows,
    page,
    filterCondition,
    type,
    infoTag
  }
})
// 提交反馈接口
export const getdoctordetailList = (rows, page, doctorId, infoType) => axios.get('/doctor/queryDoctorDetail', {
  params: {
    rows,
    page,
    doctorId,
    infoType
  }
})
// 获取详情接口
export const getMediadetailList = (infoId, type, t) =>{
  return axios.get('/scienceInfo/queryScienceDetail', {
    params: {
      infoId,
      type,
      t
    }
  })
} 

// 获取详情接口
export const getdisdetailList = (infoId, type) => axios.get('/scienceInfo/queryTagDetail', {
  params: {
    infoId,
    type
  }
})

// 获取疾病tag接口
export const getdistagdetailList = (rows, page, type, infoTag) => axios.get('/common/mediaInfoList', {
  params: {
    rows,
    page,
    type,
    infoTag
  }
})

// 获取搜索接口
export const getsearchdetailList = (rows, page, searchInfo) => axios.get('/search/querySearchDetail', {
  params: {
    rows,
    page,
    searchInfo
  }
})
