<template>
  <a
    :href="
      videosItem.infoType === 'audio'
        ? '/mobVideoCenterAudioDel?audioId=' + videosItem.infoId
        : videosItem.infoType === 'article'
        ? '/mobVideoCenterArticleDel?articleId=' + videosItem.infoId
        : '/mobVideoCenterDel?videoId=' + videosItem.infoId
    "
  >
<div class="box" :style="activeStyle"  @mouseover="mouseOver()"
      @mouseleave="mouseLeave()">
  <div class="videos-item">
    <!-- <img :src="showImage" alt="" @load="imageLoad"> -->
    <!-- <img :src="videosItem.backImg" alt=""> -->
    <img :src="videosItem.backImg ? videosItem.backImg : imgbg[videosItem.infoType]" alt="" v-if="videosItem.backImg" >
    <!-- <img :src="imgbg[videosItem.infoType]" alt="" v-else> -->
    <span class="time" v-if="videotype != '文章'">{{duration}}</span>
    <div :class="{mask : isactive}"></div>
    <div :class="{maskicon : isactive}"><img :src="imgstatus" :style="{ display: this.isactive ? 'block' : 'none' }"></div>
  </div>
  <div class="videos-info">
      <div class="introduce">{{videosItem.infoTitle}}</div>
      <div class="video">
        <div class="frequency">{{videosItem.browse}}次播放</div>
        <div class="type" :style="tycolor">{{videotype}}</div>
      </div>
    </div>
    </div>
      </a>
</template>

<script>
import stohs from '@/util/s_to_hs'
// import { Encrypt } from '@/util/EncryptionToDecrypt'
export default {
  name: 'VideosListItem',
  data () {
    return {
      isactive: false,
      imgstatus: require('assets/zhuangtai/p1.svg'),
      duration: '',
      videotype: '',
      types: {
        audio: '音频',
        video: '视频',
        qa: '问答',
        article: '文章'
      },
      imgbg: {
        audio: require('../../../assets/cover/audio.png'),
        video: require('../../../assets/cover/video.png'),
        article: require('../../../assets/cover/article.png')
      }
    }
  },
  props: {
    videosItem: {
      type: Object,
      default () {
        return {}
      }
    },
    videosIndex: {
      type: Number
    },
    stype: {
      type: String
    }
  },
  mounted () {
    this.duration = stohs(this.videosItem.duration)
    this.videotype = this.types[this.videosItem.infoType]
  },
  watch: {
    videosItem: function (newVal) {
      this.duration = stohs(this.videosItem.duration)
      this.videotype = this.types[this.videosItem.infoType]
    },
    immediate: true,
    deep: true
  },
  computed: {
    showImage () {
      return this.videosItem.img || this.videosItem.image || this.videosItem.show.img
    },
    activeStyle () {
      return { top: (64 + 64 * this.videosIndex) + 'px' }
    },
    tycolor () {
      if (this.videosItem.infoType === 'video') {
        return { color: '#4263EB', border: '1px solid #4263EB' }
      } else if (this.videosItem.infoType === 'audio') {
        return { color: '#F6A609', border: '1px solid #F6A609' }
      } else if (this.videosItem.infoType === 'article') {
        return { color: '#595959', border: '1px solid #595959' }
      } else {
        return {}
      }
    }
  },
  methods: {
    imageLoad () {
      this.$bus.$emit('itemImageLoad')
    },
    // itemClick (e) {
    //   if (this.videosItem.infoType === 'audio') {
    //     // this.$router.push('/voicedetail/' + 1)
    //     // sessionStorage.setItem('nav', '3')
    //     // this.$router.push({ path: '/mobVideoCenterAudioDel', query: { i: Encrypt(e) } })
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterAudioDel' })
    //     this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: e } })
    //   }
    //   if (this.videosItem.infoType === 'article') {
    //     // this.$router.push('/articledetail/' + 1)
    //     // sessionStorage.setItem('nav', '4')
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterArticleDel' })
    //     this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: e } })
    //   }
    //   if (this.videosItem.infoType === 'video') {
    //     // this.$router.push('/videodetail/' + 1)
    //     // sessionStorage.setItem('nav', '2')
    //     this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: e } })
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterDel' })
    //   }
    // },
    mouseOver () {
      this.isactive = true
      if (this.videosItem.infoType === 'video') {
        this.imgstatus = require('assets/zhuangtai/p1.svg')
      }
      if (this.videosItem.infoType === 'audio') {
        this.imgstatus = require('assets/zhuangtai/y1.svg')
      }
      if (this.videosItem.infoType === 'article') {
        this.imgstatus = require('assets/zhuangtai/w1.svg')
      }
    },
    // 移出
    mouseLeave () {
      this.isactive = false
    }
  }
}
</script>

<style scoped>
.box{
  position: relative;
width: 269px;
height: 52px;
left: 12px;
/* top: 61px; */
background: #FFFFFF;
border: 1px solid #F5F5F5;
box-sizing: border-box;
border-radius: 4px;
}
  .videos-item img {
    position: absolute;
    width: 100px;
    height: 50px;
    border-radius: 3px 0px 0px 3px;
  }
.videos-info{
  flex:1;
  margin-left: 100px;
  /* position: relative; */
}
a {
  color: #262626;
  text-decoration: none;
}
.time{
  position: absolute;
  top: 27px;
  left: 52px;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #262626;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  opacity: 0.7;
}
.videos-info .introduce{
  margin-top: 4px;
  margin-left: 4px;
  font-size: 14px;
  line-height: 22px;
  width: 160px;
  height: 22px;
  overflow: hidden;
  text-overflow:ellipsis;
  white-space: nowrap;
}
.videos-info .video{
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 17px;
  /* display: flex; */
}
.frequency{
  font-size: 12px;
  line-height: 22px;
  color: #8C8C8C;
  /* width: 67px; */
  height: 20px;
  float: left;
}
.type{
  padding: 0px 4px;
  /* width: 20px; */
  font-size: 12px;
  border: 1px solid #4263EB;
  border-radius: 4px;
  text-align: center;
  float: right;
}
.mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 50px;
    background: rgba(0,0,0,0.5);
}
.maskicon{
  position: absolute;
  left: 45px;
  top: 18px;
}
.maskicon img{
  position: absolute;
  width: 16px;
  height: 16px;
  left: 29.17%;
  right: 17.71%;
  top: 22.55%;
  bottom: 22.55%;
}
</style>
