<template>
<a
    :href="
      videos.infoType === 'audio'
        ? '/mobVideoCenterAudioDel?audioId=' + videos.infoId
        : videos.infoType === 'article'
        ? '/mobVideoCenterArticleDel?articleId=' + videos.infoId
        : '/mobVideoCenterDel?videoId=' + videos.infoId
    "
  >
<div class="box">
  <!-- <div class="videos-item" @click="itemClick(videos.infoId)" @mouseover="mouseOver()" -->
  <div class="videos-item" @mouseover="mouseOver()"
      @mouseleave="mouseLeave()">
    <!-- <img :src="showImage" alt="" @load="imageLoad"> -->
    <img :src="videos.backImg ? videos.backImg : imgbg[videos.infoType]" alt="" :style="{height: (types[this.videos.infoType] != '视频') ? 'unset' : 100 + '%', 'margin-top': (types[this.videos.infoType] != '视频') ? 12 + 'px' : 'unset' }">
    <!-- <img :src="imgbg[videos.infoType]" alt="" v-else> -->
    <span class="time" v-if="types[this.videos.infoType] != '文章'">{{duration}}</span>
    <div :class="{mask : isactive}"></div>
    <div :class="{maskicon : isactive}"><img :src="imgstatus" :style="{ display: this.isactive ? 'block' : 'none'}"></div>
  </div>
  <div class="videos-info">
      <div class="introduce">
        <span :style="tycolor" class="type">{{types[this.videos.infoType]}}</span>
        <span v-html="infoTitle" class="infoTitle"></span>
      </div>
      <div class="abstract">
        <span v-html="infoContent"></span>
      </div>
      <div class="message">
        <el-avatar :size="24" :src="videos.avatar"></el-avatar>
       <!-- <img :src="videos.avatar" alt="" style="width: 24px;
      height: 24px; border-radius:90px"> -->
        <span class="name">{{videos.doctorName}}</span>
        <span class="position">{{videos.doctorTitle}}</span>
        <span class="times">{{videos.browse}}次播放</span>
        <span class="day">{{time}}</span>
      </div>
  </div>
</div>
</a>
</template>

<script>
import stohs from 'util/s_to_hs'
import formateDate from 'util/formateDate'
import HighLight from 'util/highLight'
// import { Encrypt } from '@/util/EncryptionToDecrypt'
export default {
  name: 'VideosList',
  components: {
  },
  data () {
    return {
      isactive: false,
      imgstatus: '',
      duration: '',
      time: '',
      types: {
        audio: '音频',
        video: '视频',
        qa: '问答',
        article: '文章'
      },
      videotype: '',
      imgbg: {
        audio: require('../../../assets/cover/audio.png'),
        video: require('../../../assets/cover/video.png'),
        article: require('../../../assets/cover/article.png')
      }
    }
  },
  props: {
    videos: {
      type: Object,
      default () {
        return {}
      }
    },
    stype: {
      type: String
    },
    search_text: {
      type: String
    },
    totop: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  computed: {
    tycolor () {
      if (this.videos.infoType === 'video') {
        return { color: '#4263EB', 'border-color': '#4263EB' }
      } else if (this.videos.infoType === 'audio') {
        return { color: '#F6A609', 'border-color': '#F6A609' }
      } else if (this.videos.infoType === 'article') {
        return { color: '#595959', 'border-color': '#595959' }
      } else {
        return {}
      }
    },
    infoTitle () {
      return HighLight('infoTitle', this.videos.infoTitle, this.search_text)
      // let infoTitle = this.videos.infoTitle
      // if (!infoTitle) {
      //   return ''
      // }
      // if (this.search_text && this.search_text.length > 0) {
      //   // 匹配关键字正则
      //   const replaceReg = new RegExp(this.search_text, 'g')
      //   // 高亮替换v-html值
      //   const replaceString = '<span class="search-text" style= "color:#6F8CF3">' + this.search_text + '</span>'
      //   // 开始替换
      //   infoTitle = infoTitle.replace(replaceReg, replaceString)
      // }
      // return infoTitle
    },
    infoContent () {
      return HighLight('infoContent', this.videos.infoContent, this.search_text)
    //   let infoContent = this.videos.infoContent
    //   if (!infoContent) {
    //     return ''
    //   }
    //   if (this.search_text && this.search_text.length > 0) {
    //     // 匹配关键字正则
    //     const replaceReg = new RegExp(this.search_text, 'g')
    //     // 高亮替换v-html值
    //     const replaceString = '<span class="search-text" style= "color:#6F8CF3">' + this.search_text + '</span>'
    //     // 开始替换
    //     infoContent = infoContent.replace(replaceReg, replaceString)
    //   }
    //   return infoContent
    }
  },
  mounted () {
    this.duration = stohs(this.videos.duration)
    // this.videotype = this.
    const d = new Date(this.videos.startTime)
    this.time = formateDate(d)
    // console.log(this.videos)
  },
  watch: {
    videos: function (newVal) {
      this.duration = stohs(this.videos.duration)
      // this.videotype = this.
      const d = new Date(this.videos.startTime)
      this.time = formateDate(d)
    },
    immediate: true,
    deep: true
  },
  methods: {
    imageLoad () {
      this.$bus.$emit('itemImageLoad')
    },
    // itemClick (e) {
    //   if (this.totop) {
    //     window.scrollTo(0, 0)
    //   }
    //   if (this.videos.infoType === 'audio') {
    //     // // sessionStorage.setItem('nav', '3')
    //     // this.$router.push('/voicedetail/' + 1)
    //     // this.$router.push({ path: '/mobVideoCenterAudioDel', query: { i: Encrypt(e) } })
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterAudioDel' })
    //     this.$router.push({ path: '/mobVideoCenterAudioDel', query: { audioId: e } })
    //   }
    //   if (this.videos.infoType === 'article') {
    //     // // sessionStorage.setItem('nav', '4')
    //     // this.$router.push('/articledetail/' + 1)
    //     sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterArticleDel' })
    //     this.$router.push({ path: '/mobVideoCenterArticleDel', query: { articleId: e } })
    //   }
    //   if (this.videos.infoType === 'video') {
    //     // // sessionStorage.setItem('nav', '2')
    //     // this.$router.push('/videodetail/' + 1)
    //     // this.$router.push({ path: '/mobVideoCenterDel', query: { i: Encrypt(e) } })
    //     this.$router.push({ path: '/mobVideoCenterDel', query: { videoId: e } })
    //     // sessionStorage.setItem('id', e)
    //     // this.$router.push({ path: '/mobVideoCenterDel' })
    //   }
    // },
    mouseOver () {
      this.isactive = true
      if (this.videos.infoType === 'video') {
        this.imgstatus = require('assets/zhuangtai/p1.svg')
      }
      if (this.videos.infoType === 'audio') {
        this.imgstatus = require('assets/zhuangtai/y1.svg')
      }
      if (this.videos.infoType === 'article') {
        this.imgstatus = require('assets/zhuangtai/w1.svg')
      }
    },
    // 移出
    mouseLeave () {
      this.isactive = false
    }
  }
}
</script>

<style scoped>
.box{
  display: flex;
  width: 950px;
  height: 110px;
  /* padding-bottom: 10px; */
  position: relative;
  border: 1px solid #F5F5F5;
  border-radius: 4px;
  background: #FFFFFF;
  margin-bottom: 20px;
}
::v-deep .el-avatar>img{
  height: unset;
  width: 100%;
}
.videos-item{
  position: relative;
  width: 220px;
  height: 110px;
}
  .videos-item img {
    position: absolute;
    width: 100%;
    /* height: 110px; */
    border-radius: 3px 0px 0px 3px;
  }
.videos-info{
  flex:1;
  margin-left: 14px;
  margin-top: 10px;
  /* position: relative; */
}
.time{
  position: absolute;
  top: 86px;
  left: 176px;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #262626;
  padding: 1px 4px 1px 4px;
  border-radius: 4px;
  opacity: 0.7;
}
.infoTitle{
  text-overflow: ellipsis;
   overflow: hidden;
}
.videos-info .abstract{
  margin-top: 4px;
  margin-left: 4px;
  font-size: 14px;
  line-height: 20px;
  /* width: 710px; */
  margin-right: 14px;
  height: 40px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  word-wrap:break-word;
  padding-bottom: 3px;
  color: #8C8C8C;
  border-bottom: 1px solid #F5F5F5;
}
.videos-info .video{
  margin-top: 2px;
  margin-left: 4px;
  margin-right: 17px;
  /* display: flex; */
}

.type{
  padding: 0px 4px;
  width: 20px;
  font-size: 12px;
  border: 1px solid #4263EB;
  color: #4263EB;
  border-radius: 4px;
  text-align: center;
  margin-right: 10px;
}
.message{
  position: relative;
  margin-top: 5px;
}
.name{
  position: absolute;
  /* width: 36px; */
  height: 20px;
  margin-top: 2px;
  margin-left: 4px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  /* font-family: 'OPPOSans B'; */
  font-weight: bold;
}
.position{
  position: absolute;
  /* width: 48px; */
  height: 20px;
  left: 92px;
  top: 2px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
}
.times{
  /* width: 72px; */
  position: absolute;
  height: 20px;
  top: 2px;
  font-size: 12px;
  line-height: 20px;
  left: 500px;
  color: #BFBFBF;
}
.day{
  /* width: 72px; */
  position: absolute;
  height: 20px;
  top: 2px;
  font-size: 12px;
  line-height: 20px;
  /* left: 617px; */
  right: 10px;
  color: #BFBFBF;
}
.mask{
    position: absolute;
    top: 0;
    left: 0;
    width: 220px;
    height: 110px;
    background: rgba(0,0,0,0.5);
}
.maskicon{
  position: absolute;
  left: 85px;
  top: 40px;
}
.maskicon img{
  position: absolute;
  width: 45px;
  height: 45px;
  left: 29.17%;
  right: 17.71%;
  top: 22.55%;
  bottom: 22.55%;
}
a {
  text-decoration: none;
  color: unset;
}
</style>
