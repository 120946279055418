<template>
  <div class="title" :style="{left: xianshi === 'true' ? 450 + 'px' : ''}">
    <div class="bgboxs" :style="activeStyle"></div>
    <div class="tbox">
      <div class="recommend">
        {{Title}}
      </div>
      <div class="erecommend">
       <span>{{eTitle}}</span>
      </div>
    </div>
    <div class="pa" v-if="xianshi === 'true'">
      <div v-for="(item, index) in ss" :key="index" @click="itemclick(index)" :style="{ color: (index === currentIndex ? '#4263EB' : '#595959')}">{{item}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RecommendTitle',
  data () {
    return {
      ss: ['最新发布', '点击排行'],
      currentIndex: 0
    }
  },
  props: {
    Title: String,
    eTitle: String,
    color: {
      type: String,
      default: 'blue'
    },
    xianshi: {
      type: String
    }
  },
  mounted () {
    // console.log(this.xianshi)
  },
  computed: {
    activeStyle () {
      if (this.xianshi === 'true') {
        return { 'background-color': this.color, position: 'absolute', bottom: 20 + 'px' }
      }
      return { 'background-color': this.color }
    }
  },
  methods: {
    itemclick (index) {
      this.currentIndex = index
      this.$emit('paixu', index)
    }
  }
}
</script>

<style scoped>
.title{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px;
  position: relative;
  width: 300px;
  height: 72px;
  /* left: 450px; */
  top: 0px;
}
.recommend{
  position: absolute;
  width: 144px;
  height: 54px;
  left: 78px;
  top: 0px;
  /* font-family: 'OPPOSans B'; */
  font-weight: bold;
  font-style: normal;
  font-size: 36px;
  line-height: 54px;
  text-align: center;
  z-index: 99;
}
.erecommend{
  position: absolute;
  width: 300px;
  /* left: 50%; */
  transform: translateX(-50%);
  height: 28px;
  /* left: 78px; */
  top: 58px;
  /* font-family: Microsoft YaHei; */
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 14px;
  text-align: center;
  /* text-transform: uppercase; */
  color: #D9D9D9;
}
.bgboxs{
  position: relative;
  /* left: 0%;
  right: 0%;
  top: 52.78%;
  bottom: 27.78%;
  mix-blend-mode: normal; */
  bottom: -10px;
  width: 300px;
  height: 14px;
  opacity: 0.4;
  z-index: 9;
}
.pa{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  font-size: 20px;
  line-height: 28px;
  margin-top: 200px;
}
.pa   div{
  margin: 0  25px;
  cursor: pointer;
}
</style>
